import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";

const fetchTests = async (token) => {
  const response = await axios.get("/api/tests/get/all", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const TestSelection = () => {
  const { user, token, logout } = useAuth();
  const {
    data: tests,
    error,
    isLoading,
  } = useQuery("tests", () => fetchTests(token));

  if (isLoading)
    return <div className="text-center mt-8">Loading tests...</div>;
  if (error)
    return (
      <div className="text-center mt-8 text-red-500">
        Error loading tests: {error.message}
      </div>
    );

  return (
    <div className="min-h-screen bg-[#151523] py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-[#fd8b9e] to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold">
                Welcome, {user.fullName}
              </h1>
              <p className="mt-2 text-gray-600">Select a test to begin:</p>
            </div>
            <div className="divide-y divide-gray-200">
              {tests.map((test) => (
                <Link
                  key={test._id}
                  to={`/test/${test._id}`}
                  className="py-4 block hover:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-8 w-8 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {test.title}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        College Code: {test.collegeCode}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="mt-8">
              <button
                onClick={logout}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-[#fd8b9e] hover:scaler-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fd8b9e]"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSelection;
