import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const { login, resendOTP, verifyEmail } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const onSubmit = async (data) => {
    setIsLoading(true);
    setLoginError("");
    try {
      await login(data.email, data.password);
      navigate("/tests");
    } catch (error) {
      const errorMessage =
        error.message || error.response?.data?.error || "An error occurred";

        console.log(errorMessage)
      if (
        errorMessage.includes("Verification OTP has been sent to your email")
      ) {
        setUserEmail(data.email);
        setUserPassword(data.password);
        setShowVerification(true);
        try {
          await resendOTP(data.email);
          setLoginError("Please verify your email. An OTP has been sent.");
        } catch (otpError) {
          setLoginError("Failed to send verification OTP. Please try again.");
        }
      } else {
        setLoginError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      await resendOTP(userEmail);
      setOtpError("");
    } catch (error) {
      setOtpError("Failed to resend OTP. Please try again.");
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      await verifyEmail(userEmail, otp);
      await login(userEmail, userPassword);
      navigate("/tests");
    } catch (error) {
      setOtpError(error.message || "Invalid OTP. Please try again.");
    }
  };

  if (showVerification) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#151523]">
        <div className="px-8 py-6 w-full md:w-1/3 mt-4 text-left bg-white shadow-lg sm:rounded-lg">
          <div className="flex justify-center items-center mb-6">
            <img
              src="https://sqilco.com/sqilco.svg"
              alt="Sqilco"
              className="w-[30%]"
            />
          </div>
          <h3 className="text-2xl font-bold text-center">Verify Your Email</h3>
          <p className="text-center text-gray-600 mt-2">
            Enter the verification code sent to {userEmail}
          </p>
          <form onSubmit={handleOtpSubmit} className="mt-4">
            <div>
              <label className="block" htmlFor="otp">
                Enter OTP
              </label>
              <input
                type="text"
                placeholder="Enter the OTP sent to your email"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              {otpError && (
                <p className="text-red-500 text-xs mt-1">{otpError}</p>
              )}
            </div>
            <div className="flex flex-col space-y-4 mt-6">
              <button
                type="submit"
                className="px-6 py-2 text-black bg-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50"
              >
                Verify OTP
              </button>
              <button
                type="button"
                onClick={handleResendOtp}
                className="px-6 py-2 text-[#fd8b9e] border border-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none"
              >
                Resend OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#151523]">
      <div className="px-8 py-6 mt-4 text-left w-full md:w-1/3 bg-white shadow-lg sm:rounded-lg">
        <div className="flex justify-center items-center">
          <img
            src="https://sqilco.com/sqilco.svg"
            alt="Sqilco"
            className="w-[30%]"
          />
        </div>
        <h3 className="text-2xl font-bold text-center mt-4">
          Login to Your Account
        </h3>

        {loginError && (
          <p className="text-red-500 text-sm mt-2 text-center">{loginError}</p>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div>
            <label className="block" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block">Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              })}
            />
            {errors.password && (
              <p className="text-red-500 text-xs mt-1">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-4 mt-4">
            <button
              className="px-6 py-2 text-black bg-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
            <div className="flex justify-between items-center">
              <Link
                to="/register"
                className="text-sm text-[#f85e77] hover:underline"
              >
                Register
              </Link>
              <Link
                to="/forgot-password"
                className="text-sm text-[#f85e77] hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
