import React, { useCallback } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const QuestionCard = React.memo(
  ({ question, onAnswerChange, selectedAnswer }) => {
    const renderQuestionText = useCallback((text) => {
      const parts = text.split(/```([\s\S]*?)```/);
      return parts.map((part, index) => {
        if (index % 2 === 1) {
          return (
            <SyntaxHighlighter
              key={index}
              language="javascript"
              style={docco}
              className="my-4 rounded-md"
            >
              {part.trim()}
            </SyntaxHighlighter>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    }, []);

    const handleClearAnswer = useCallback(() => {
      onAnswerChange(null);
    }, [onAnswerChange]);

    return (
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">
          {renderQuestionText(question.question)}
        </h2>
        <div className="space-y-2">
          {question.options.map((option, index) => (
            <label
              key={index}
              className="flex items-center space-x-3 p-2 rounded-md hover:bg-[#151523] hover:bg-opacity-30 hover:text-white"
            >
              <input
                type="radio"
                name={`question-${question._id}`}
                value={option}
                checked={selectedAnswer === option}
                onChange={() => onAnswerChange(option)}
                className="form-radio h-5 w-5 text-[#fd8b9e]"
              />
              <span className="text-gray-700">{option}</span>
            </label>
          ))}
        </div>
        <div className="mt-4">
          <button
            onClick={handleClearAnswer}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Clear Answer
          </button>
        </div>
      </div>
    );
  }
);

export default QuestionCard;
