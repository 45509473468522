// App.js (updated with new routes)
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Login from "./components/LoginAndRegister/Login";
import Register from "./components/LoginAndRegister/Register";

import TestSelection from "./components/Test-Selections/TestSelection";
import TestPage from "./components/Test-Page/TestPage";
import { AuthProvider, useAuth } from "./context/AuthContext";
import ForgotPassword from "./components/ForgotAndReset-Password/ForgotPassword";
import ResetPassword from "./components/ForgotAndReset-Password/ResetPassword";

const queryClient = new QueryClient();

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <div className="min-h-screen bg-gray-100">
            <Routes>
              <Route path="/" element={<Navigate to="/tests" replace />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/tests"
                element={
                  <ProtectedRoute>
                    <TestSelection />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test/:testId"
                element={
                  <ProtectedRoute>
                    <TestPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
