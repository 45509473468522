import React, { useRef, useEffect } from "react";

const CameraFeed = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return (
      <div className="bg-gray-200 w-full h-48 flex items-center justify-center text-gray-500">
        Camera not available
      </div>
    );
  }

  return (
    <div className="rounded-lg overflow-hidden shadow-lg">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-auto"
      />
    </div>
  );
};

export default CameraFeed;
