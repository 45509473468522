import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import InstructionsModal from "../Test-Instructions/InstructionModal";
import CameraFeed from "../Camera-Feed";
import QuestionCard from "../Question-Card/Question-Card";
import CountdownTimer from "../Countdown-Timer";
import ScoreCard from "../Test-Results/ScoreCard";

const API_BASE_URL = "/api";

// Utility functions
const fetchTest = async (testId, token) => {
  const response = await axios.get(`${API_BASE_URL}/tests/${testId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const submitSection = async ({
  testId,
  sectionId,
  answers,
  score,
  questionsAttempted,
  startTime,
  token,
}) => {
  console.log("Submitting section:", {
    testId,
    sectionId,
    answers,
    score,
    questionsAttempted,
    startTime,
  });
  const response = await axios.post(
    `${API_BASE_URL}/tests/submit/${testId}/${sectionId}`,
    { answers, score, questionsAttempted, startTime },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const submitTest = async ({ testId, token }) => {
  const response = await axios.post(
    `${API_BASE_URL}/tests/submit/${testId}`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const formatTime = (seconds) => {
  if (seconds == null) return "00:00:00";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-[#151523] bg-opacity-75 flex items-center justify-center z-50">
    <div className="text-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#fd8b9e] mb-4"></div>
      <p className="text-white text-xl font-semibold">Loading...</p>
    </div>
  </div>
);

const TestPage = () => {
  const { testId } = useParams();
  const { token } = useAuth();
  const queryClient = useQueryClient();

  // State management
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [sectionStartTime, setSectionStartTime] = useState(null);
  const [overallTimeLeft, setOverallTimeLeft] = useState(null);
  const [testCompleted, setTestCompleted] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [screenSwitchWarnings, setScreenSwitchWarnings] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [stream, setStream] = useState(null);
  const [testStarted, setTestStarted] = useState(false);
  const [isSubmittingSection, setIsSubmittingSection] = useState(false);
  const [isSubmittingTest, setIsSubmittingTest] = useState(false);
  const [showCountdown, setShowCountdown] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionsGranted, setPermissionsGranted] = useState(true);
  const [timer, setTimer] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState({
    isSubmitting: false,
    isComplete: false,
    error: null,
  });

  const STORAGE_KEY = "testProgress";

  const saveProgress = (progress) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(progress));
  };

  const loadProgress = () => {
    const saved = localStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : null;
  };
  
  const clearProgress = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  // Fetch test data
  const {
    data: testData,
    error,
    isLoading: isTestLoading,
  } = useQuery(["test", testId], () => fetchTest(testId, token), {
    refetchOnWindowFocus: false,
    refetchInterval: (data) => {
      return data?.status === "waiting" ? 5000 : false;
    },
  });

  // Refetch test data function
  const refetchTestData = useCallback(async () => {
    setIsLoading(true);
    try {
      await queryClient.invalidateQueries(["test", testId]);
      const updatedData = await queryClient.fetchQuery(["test", testId], () =>
        fetchTest(testId, token)
      );
      return updatedData;
    } finally {
      setIsLoading(false);
    }
  }, [queryClient, testId, token]);

  // Calculate score function
  const calculateScore = useCallback((sectionAnswers, questions) => {
    let score = 0;
    let questionsAttempted = 0;

    questions.forEach((question, index) => {
      if (sectionAnswers[index] !== undefined) {
        questionsAttempted++;
        if (question.correctAnswer === sectionAnswers[index]) {
          score += 1;
        } else {
          score -= 0.25;
        }
      }
    });

    return { score, questionsAttempted };
  }, []);

  useEffect(() => {
    if (testData) {
      const savedProgress = loadProgress();
      if (savedProgress && savedProgress.testId === testId) {
        setCurrentSectionIndex(savedProgress.currentSectionIndex);
        setCurrentQuestionIndex(savedProgress.currentQuestionIndex);
        setAnswers(savedProgress.answers);
        setTimer(savedProgress.remainingTime);
        setTestStarted(true);
        setShowInstructions(false);
      }
    }
  }, [testData, testId]);

  useEffect(() => {
    if (testStarted && !testCompleted) {
      const progress = {
        testId,
        currentSectionIndex,
        currentQuestionIndex,
        answers,
        remainingTime: timer,
        timestamp: new Date().getTime()
      };
      saveProgress(progress);
    }
  }, [
    testStarted,
    testCompleted,
    testId,
    currentSectionIndex,
    currentQuestionIndex,
    answers,
    timer
  ]);

  const handleTestComplete = useCallback(() => {
    clearProgress();
    setTestCompleted(true);
  }, []);

  // Submit section mutation
  const sectionSubmitMutation = useMutation(submitSection, {
    onMutate: () => setIsSubmittingSection(true),
    onSuccess: (data) => {
      if (
        testData?.test?.sections &&
        currentSectionIndex < testData.test.sections.length - 1
      ) {
        setCurrentSectionIndex((prev) => prev + 1);
        setCurrentQuestionIndex(0);
        setAnswers({});
        setSectionStartTime(new Date().toISOString());
      } else {
        handleSubmitTest();
      }
    },
    onSettled: () => setIsSubmittingSection(false),
  });

  // Submit test mutation
  const testSubmitMutation = useMutation(submitTest, {
    onMutate: () => setIsSubmittingTest(true),
    onSuccess: (data) => {
      setTestCompleted(true);
      setTestResults(data.result);
    },
    onSettled: () => setIsSubmittingTest(false),
  });

  // Handle next question
  const handleNextQuestion = useCallback(() => {
    if (!testData?.test?.sections || isSubmittingSection) return;

    const currentSection = testData.test.sections[currentSectionIndex];
    if (currentSection) {
      if (currentQuestionIndex < currentSection.questions.length - 1) {
        setCurrentQuestionIndex((prev) => prev + 1);
      } else {
        // Use the most recent answers state
        setAnswers((currentAnswers) => {
          setTimeout(() => {
            handleSubmitSection(currentAnswers);
          }, 100);
          return currentAnswers;
        });
      }
    }
  }, [
    currentSectionIndex,
    currentQuestionIndex,
    testData,
    isSubmittingSection,
  ]);

  useEffect(() => {
    const confirmSubmit = (event) => {
      if (isSubmittingSection) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", confirmSubmit);
    return () => window.removeEventListener("beforeunload", confirmSubmit);
  }, [isSubmittingSection]);

  // Handle submit section
  const handleSubmitSection = useCallback(
    (currentAnswers = answers) => {
      if (!testData?.test?.sections || isSubmittingSection) return;

      const currentSection = testData.test.sections[currentSectionIndex];
      if (!currentSection) return;

      const sectionAnswers = currentSection.questions.map((_, index) => {
        const answer = currentAnswers[index];
        return answer === undefined || answer === "" || answer === "unattempted"
          ? null
          : answer;
      });

      const isAllAnswersPresent = currentSection.questions.every(
        (_, index) => currentAnswers[index] !== undefined
      );

      const { score, questionsAttempted } = calculateScore(
        sectionAnswers,
        currentSection.questions
      );

      setIsSubmittingSection(true);
      sectionSubmitMutation.mutate({
        testId,
        sectionId: currentSection.id,
        answers: sectionAnswers,
        score,
        questionsAttempted,
        startTime: sectionStartTime,
        token,
      });
    },
    [
      testId,
      currentSectionIndex,
      answers,
      sectionStartTime,
      token,
      testData,
      sectionSubmitMutation,
      isSubmittingSection,
      calculateScore,
    ]
  );

  // Handle submit test
  const handleSubmitTest = useCallback(async () => {
    if (isSubmittingTest || !testData?.test?.sections) return;
    try {
      const result = await testSubmitMutation.mutateAsync({ testId, token });
      const updatedData = await fetchTest(testId, token);
      clearProgress();
      setTestCompleted(true);
      setTestResults(updatedData.result);
    } catch (error) {
      console.error("Error submitting test:", error);
    } finally {
      setIsSubmittingTest(false);
    }

    // Cleanup
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  }, [testId, token, testSubmitMutation, isSubmittingTest, stream, testData]);

  // Start test function
  const startTest = async () => {
    setIsLoading(true);
    try {
      const updatedData = await refetchTestData();
      if (updatedData.status === "ongoing") {
        setShowInstructions(false);
        setTestStarted(true);
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Screen switch warning handler
  const handleScreenSwitch = useCallback(() => {
    setScreenSwitchWarnings((prev) => {
      const newWarnings = prev + 1;
      if (newWarnings >= 3) {
        handleSubmitTest();
      } else {
        alert(`Warning: Screen switch detected. Warning ${newWarnings}/3`);
      }
      return newWarnings;
    });
  }, [handleSubmitTest]);

  // Effect for handling visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && testStarted) {
        handleScreenSwitch();
      }
    };

    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
      if (!document.fullscreenElement && testStarted) {
        handleScreenSwitch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleScreenSwitch, testStarted]);

  // Effect for handling test timer
  useEffect(() => {
    if (testData?.test?.endTime && testData.status === "ongoing") {
      setSectionStartTime((prev) => prev || new Date().toISOString());

      const endTime = new Date(testData.test.endTime).getTime();
      const timerId = setInterval(() => {
        const now = new Date().getTime();
        const timeLeft = Math.max(0, Math.floor((endTime - now) / 1000));
        setOverallTimeLeft(timeLeft);
        if (timeLeft <= 0) {
          clearInterval(timerId);
          handleSubmitSection(answers);
          clearProgress();
          handleSubmitTest();
        }
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [testData, handleSubmitTest, handleSubmitSection, answers]);

  // Render loading state
  if (isTestLoading || isLoading) {
    return <LoadingSpinner />;
  }

  if (testData.status === "ended") {
    return (
      console.log("test status : ", testData.status),
      (
        <div className="text-center mt-8">
          <h1>The test is ended</h1>
        </div>
      )
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="text-center mt-8 text-red-500">
        Error loading test: {error.message}
      </div>
    );
  }

  // Render no data state
  if (!testData) {
    return <div className="text-center mt-8">No test data available.</div>;
  }

  // Handle completed state - show ScoreCard directly
  if (testData.status === "completed" || testCompleted) {
    return <ScoreCard result={testResults || testData.result} />;
  }

  // Handle waiting state
  if (testData.status === "waiting" && !testStarted) {
    const savedProgress = loadProgress();
    if (savedProgress && savedProgress.testId === testId) {
      return (
        <div className="text-center mt-8">
          <h2>Resume Test?</h2>
          <p>You have a saved test in progress.</p>
          <button
            onClick={() => {
              setCurrentSectionIndex(savedProgress.currentSectionIndex);
              setCurrentQuestionIndex(savedProgress.currentQuestionIndex);
              setAnswers(savedProgress.answers);
              setTimer(savedProgress.remainingTime);
              setTestStarted(true);
              setShowInstructions(false);
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
          >
            Resume Test
          </button>
          <button
            onClick={() => {
              clearProgress();
              setShowCountdown(true);
            }}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Start New Test
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center h-screen">
          <CountdownTimer
            initialTime={testData.timeToStart}
            onComplete={() => {
              setShowCountdown(false);
              setShowInstructions(true);
            }}
          />
        </div>
      );
    }
  }

  // Show instructions
  if (showInstructions || (!testStarted && testData.status !== "waiting")) {
    return (
      <InstructionsModal
        onStartTest={startTest}
        permissionsGranted={permissionsGranted}
      />
    );
  }

  // Handle completed state
  if (testData.status === "completed" || testCompleted) {
    return <ScoreCard result={testResults || testData.result} />;
  }

  // Handle submitting state
  if (isSubmittingTest) {
    return <LoadingSpinner />;
  }

  // Only proceed if test is ongoing and started
  if (testData.status !== "ongoing" || !testStarted) {
    return null;
  }

  const currentSection = testData.test.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  // Handle fullscreen requirement
  if (!isFullScreen) {
    return (
      <div className="fixed inset-0 bg-gray-800 text-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Full Screen Required</h2>
          <p className="mb-4">
            Please return to full-screen mode to continue the test.
          </p>
          <p className="mb-4">Warnings: {screenSwitchWarnings}/3</p>
          <button
            onClick={() => document.documentElement.requestFullscreen()}
            className="bg-blue-500 hover:bg-[#fd8b9e] text-white font-bold py-2 px-4 rounded"
          >
            Enter Full Screen
          </button>
        </div>
      </div>
    );
  }

  // Main test interface
  return (
    <div className="min-h-screen bg-[#151523]">
      <header className="bg-[#151523] text-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-col items-center">
          <div className="flex justify-center items-center">
            <img
              src="https://sqilco.com/sqilco.svg"
              alt="Sqilco"
              className="w-[100px] h-auto"
            />
          </div>
          <h1 className="text-3xl font-bold mb-2">{testData.test.title}</h1>
          <div className="text-xl font-semibold">
            Time Left: {formatTime(overallTimeLeft)}
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          {currentSection && currentQuestion && (
            <>
              <div className="mb-4 text-white">
                <h2 className="text-2xl font-semibold">
                  Section: {currentSection.title}
                </h2>
                <p>
                  Question {currentQuestionIndex + 1} of{" "}
                  {currentSection.questions.length}
                </p>
              </div>

              <QuestionCard
                question={currentQuestion}
                onAnswerChange={(answer) => {
                  setAnswers((prev) => {
                    const newAnswers = {
                      ...prev,
                      [currentQuestionIndex]: answer,
                    };
                    return newAnswers;
                  });
                }}
                selectedAnswer={answers[currentQuestionIndex]}
              />

              <div className="mt-6 flex justify-end">
                <button
                  onClick={handleNextQuestion}
                  disabled={isSubmittingSection || isSubmittingTest}
                  className={`px-4 py-2 text-[#151523] bg-[#fd8b9e] rounded-md hover:scale-105 ${
                    isSubmittingSection || isSubmittingTest
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  {isSubmittingSection ? (
                    <span>Submitting...</span>
                  ) : currentQuestionIndex ===
                    currentSection.questions.length - 1 ? (
                    currentSectionIndex ===
                    testData.test.sections.length - 1 ? (
                      "Submit Test"
                    ) : (
                      "Next Section"
                    )
                  ) : (
                    "Next Question"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </main>

      {stream && (
        <div className="fixed top-4 right-4 w-64">
          <CameraFeed stream={stream} />
        </div>
      )}
    </div>
  );
};

export default TestPage;
