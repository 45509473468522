import React from 'react';

const InstructionsModal = ({
  onRequestPermissions,
  onStartTest,
  permissionsGranted,
}) => {
  return (
    <div className="fixed inset-0 bg-[#151523] bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative mx-auto p-5 border w-[32rem] shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Test Instructions
          </h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-gray-500">1. The test must be taken in full-screen mode.</p>
            <p className="text-gray-500">2. Camera and location access are required.</p>
            <p className="text-gray-500">3. Switching screens or exiting full-screen mode will result in warnings.</p>
            <p className="text-gray-500">4. After 3 warnings, the test will be automatically submitted.</p>
            
            <h4 className="text-md font-medium text-gray-900 mt-4">Test Details:</h4>
            <ul className="list-disc list-inside text-gray-500">
              <li>Total test duration: 1 hour</li>
              <li>
                3 sections:
                <ul className="list-disc list-inside ml-4">
                  <li>Aptitude: 20 questions</li>
                  <li>Core: 20 questions</li>
                  <li>Coding: 30 questions</li>
                </ul>
              </li>
              <li>
                Scoring:
                <ul className="list-disc list-inside ml-4">
                  <li>Correct answer: +1 point</li>
                  <li>Wrong answer: -0.25 point (negative marking)</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onStartTest}
              disabled={!permissionsGranted}
              className={`px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 ${
                !permissionsGranted ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Start Test
            </button>
            {!permissionsGranted && (
              <button
                onClick={onRequestPermissions}
                className="mt-3 px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Grant Permissions
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionsModal;
