// src/components/ForgotPassword.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError("");
    setMessage("");

    try {
      const response = await axios.post("/api/auth/forgot-password", {
        email: data.email,
      });
      setMessage(response.data.message);
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "Failed to process request. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#151523]">
      <div className="px-8 py-6 mt-4 text-left w-full md:w-1/3 bg-white shadow-lg sm:rounded-lg">
        <div className="flex justify-center items-center">
          <img
            src="https://sqilco.com/sqilco.svg"
            alt="Sqilco"
            className="w-[30%]"
          />
        </div>
        <h3 className="text-2xl font-bold text-center mt-4">Forgot Password</h3>
        {message && (
          <p className="text-green-500 text-sm mt-2 text-center">{message}</p>
        )}
        {error && (
          <p className="text-red-500 text-sm mt-2 text-center">{error}</p>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div>
            <label className="block" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="flex items-baseline justify-between mt-4">
            <button
              className="px-6 py-2 text-[#151523] bg-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send Reset Link"}
            </button>
            <Link
              to="/login"
              className="text-sm text-[#f85e77] hover:underline"
            >
              Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
