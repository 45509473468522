import React from "react";

const ScoreCard = ({ result }) => {
  const calculatePercentage = (score, totalPossible) => {
    return ((score / totalPossible) * 100).toFixed(1);
  };

  const getScoreColor = (percentage) => {
    if (percentage >= 70) return "text-green-500";
    if (percentage >= 40) return "text-yellow-500";
    return "text-red-500";
  };

  return (
    <div className="min-h-screen bg-[#151523] p-6">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-center mb-8">
          <img
            src="https://sqilco.com/sqilco.svg"
            alt="Sqilco"
            className="w-[100px] h-auto"
          />
        </div>

        {/* Overall Score Card */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-2xl font-bold text-center mb-6 text-[#151523]">
            Test Results
          </h2>
          <div className="flex justify-between items-center mb-8 p-4 bg-gray-50 rounded-lg">
            <div className="text-center flex-1">
              <p className="text-gray-600 mb-1">Total Score</p>
              <p
                className={`text-3xl font-bold ${getScoreColor(
                  calculatePercentage(
                    result.totalScore,
                    result.totalPossibleScore
                  )
                )}`}
              >
                {result.totalScore}/{result.totalPossibleScore}
              </p>
              <p className="text-sm text-gray-500">
                (
                {calculatePercentage(
                  result.totalScore,
                  result.totalPossibleScore
                )}
                %)
              </p>
            </div>
            <div className="text-center flex-1">
              <p className="text-gray-600 mb-1">Questions Attempted</p>
              <p className="text-3xl font-bold text-[#151523]">
                {result.totalQuestionsAttempted}
              </p>
              <p className="text-sm text-gray-500">
                out of{" "}
                {result.sections.reduce(
                  (acc, section) => acc + section.totalQuestions,
                  0
                )}
              </p>
            </div>
          </div>

          {/* Section Scores */}
          <div className="space-y-6">
            {result.sections.map((section) => (
              <div
                key={section.sectionId}
                className="border-b last:border-b-0 pb-6"
              >
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold text-[#151523]">
                    {section.sectionName}
                  </h3>
                  <span
                    className={`font-bold ${getScoreColor(
                      calculatePercentage(
                        section.score,
                        section.totalPossibleScore
                      )
                    )}`}
                  >
                    {section.score}/{section.totalPossibleScore}
                  </span>
                </div>

                {/* Progress bar */}
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
                  <div
                    className="bg-[#fd8b9e] h-2.5 rounded-full"
                    style={{
                      width: `${calculatePercentage(
                        section.score,
                        section.totalPossibleScore
                      )}%`,
                    }}
                  ></div>
                </div>

                <div className="flex justify-between text-sm text-gray-600">
                  <span>
                    Questions Attempted: {section.questionsAttempted}/
                    {section.totalQuestions}
                  </span>
                  <span>
                    {calculatePercentage(
                      section.score,
                      section.totalPossibleScore
                    )}
                    %
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Summary */}
        <div className="text-center text-white mt-4">
          <p className="text-sm opacity-80">
            Your score has been recorded and will be reviewed by our team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
