import React, { useState, useEffect } from "react";

const CountdownTimer = ({ initialTime, onComplete }) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if (timeRemaining <= 0) {
      onComplete();
      return;
    }

    const timerId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timerId);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeRemaining, onComplete]);

  const formatTimeDays = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="text-center p-4 bg-[#151523] rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-2 text-white">Test will start in:</h2>
      <p className="text-4xl font-mono text-[#ca6f86]">
        {formatTimeDays(timeRemaining)}
      </p>
    </div>
  );
};

export default CountdownTimer;
